import React, { useState, useEffect } from "react";
import SelectSearch from 'react-select-search';
import axios from "../../Utils/Axios";
import { useAlert } from "react-alert";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay";
import { useHistory } from "react-router-dom";
import "./Leads.css"

function AddBulkLeads() {
    const alert = useAlert();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const [sources, setSources] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [country, setCountry] = useState(228);
    const [source, setSource] = useState('');
    const [query, setQuery] = useState('');
    const [comment, setComment] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [department, setDepartment] = useState('');
    const [isdCode, setIsdCode] = useState('+971');
    const [formValues, setFormValues] = useState([{ name: "", mobile: "", source: "", department: "", isdCode: '+971', country: 228, query: "", comment: "", email: "", status: 1, users: [] }])


    const getCountries = () => {
        axios("Xtras/countries", {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setCountries(response.data.countries);
                } else {
                    setLoading(false);
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
            });
    };

    const getCountryByCode = (index, id) => {
        axios(`Xtras/country_by_code?code=${formValues[index].isdCode.replace('+', '')}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    //setCountry(response.data.country)
                    handleChangeName(index, 'country', response.data.country)
                } else {
                    setLoading(false);
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
            });
    }

    const getDepartments = () => {
        axios("Xtras/departments", {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setDepartments(response.data.departments);
                } else {
                    setLoading(false);
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
            });
    };

    const getAssignee = (index, id) => {
        axios(`Leads/department_users?department=${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    handleChangeName(index, 'users', response.data.department_users);
                } else {
                    setLoading(false);
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
            });
    };

    const getSources = () => {
        axios("Xtras/sources", {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setSources(response.data.sources);
                } else {
                    setLoading(false);
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
            });
    };

    const getStatuses = () => {
        axios("Xtras/statuses", {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setStatuses(response.data.statuses);
                } else {
                    setLoading(false);
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
            });
    };


    const selectDepartment = (index, id) => {
        handleChangeName(index, 'department', id)
        handleChangeName(index, 'assigned_to', 0);
        getAssignee(index, id);
    };


    const selectCountry = (index, id) => {

        setCountry(id);
        handleChangeName(index, 'country', id)
        countries.some(item => {
            if (id === item.value) {
                handleChangeName(index, 'isdCode', '+' + item.isd_code)
            }
        });
    };

    let addFormFields = () => {
        setFormValues([...formValues, { name: "", mobile: "", source: "", department: "", isdCode: '+971', country: 228, query: "", comment: "", email: "", status: 1, users: [] }
        ])
    }

    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }

    let handleChange = (i, e) => {
        let newFormValues = [...formValues];

        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
        console.log(formValues);
    }

    let handleChangeName = (i, field, value) => {
        let newFormValues = [...formValues];

        newFormValues[i][field] = value;
        setFormValues(newFormValues);
        console.log(formValues);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        let formdata = new FormData();
        formdata.append("post_data", JSON.stringify(formValues));

        axios("Leads/add_bulk_leads", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setLoading(false);
                    alert.success(response.data.success);
                    history.push({
                        pathname: "/leads",
                        state: { data: true },
                    });
                } else {
                    setLoading(false);
                    alert.error(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.error(err.response.data.error);
            });
    };

    useEffect(() => {
        getCountries();
        getDepartments();
        getSources();
        getStatuses();
    }, []);
    return (
        <div class="container">
            <LoadingOverlay
                active={loading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "rgba(255, 255, 255, 0.9)",
                    }),
                    spinner: (base) => ({
                        ...base,
                        width: "75px",
                        "& svg circle": {
                            stroke: "rgba(0, 0, 0, 0.8)",
                        },
                    }),
                }}
                spinner={<RingLoader loading={loading} color="#36d7b7" />}
                text="..."
            >
                <form method="POST">
                    {formValues.map((element, index) => (
                        <div>
                            <div className="row bg-ad">
                                <div className="col-md-12">
                                    <h6 class="count">NO<span>{index + 1}</span></h6>
                                </div>
                                <div className="col-md-3">
                                    <div class="form-group">
                                        <label for="name">Name:</label>
                                        <input type="text" id="name" onChange={e => handleChange(index, e)} value={element.name} name="name" className="form-control form-control-lg" />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div class="form-group">
                                        <label for="email">Email:</label>
                                        <input type="email" id="email" onChange={e => handleChange(index, e)} value={element.email} name="email" className="form-control form-control-lg" />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div class="form-group">
                                        <label for="source">Source:</label>
                                        <SelectSearch options={sources} onChange={e => handleChangeName(index, 'source', e)} value={element.source} id="source" name="source" search placeholder="Choose your source" />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div class="form-group">
                                        <label for="country">Country:</label>
                                        <SelectSearch options={countries} value={element.country} onChange={e => selectCountry(index, e)} id="country" name="country" search placeholder="Choose your Country" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div class="form-group">
                                        <label for="phone">Phone:</label>
                                        <input type="tel" id="phone" name="isdCode" onChange={(e) => handleChange(index, e)} onBlur={(e) => getCountryByCode(index, e)} className="country-code" value={element.isdCode} placeholder={element.isdCode} required />
                                        <input type="tel" id="phone" name="mobile" value={element.mobile} onChange={e => handleChange(index, e)} className="phone-number" required />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div class="form-group">
                                        <label for="department">Department:</label>
                                        <SelectSearch options={departments} onChange={e => selectDepartment(index, e)} value={element.department} id="department" name="department" search placeholder="Choose your department" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div class="form-group">
                                        <label for="assigned_to">Assignee:</label>
                                        <SelectSearch options={element.users} onChange={e => handleChangeName(index, 'assigned_to', e)} value={element.assigned_to} id="assigned_to" name="assigned_to" search placeholder="Choose your User" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div class="form-group">
                                        <label for="query">Query:</label>
                                        <input type="text" id="query" name="query" onChange={e => handleChange(index, e)} className="form-control form-control-lg" value={element.query} required></input>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div class="form-group">
                                        <label for="statuses">Status:</label>
                                        <SelectSearch options={statuses} onChange={e => handleChangeName(index, 'status', e)} value={element.status} id="statuses" name="statuses" search placeholder="Choose your Status" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div class="form-group">
                                        <label for="comment">Comment:</label>
                                        <input type="text" id="comment" name="comment" onChange={e => handleChange(index, e)} value={element.comment} className="form-control form-control-lg" ></input>
                                    </div>
                                </div>

                                {
                                    index ?
                                        <i class="fa fa-times close-ad" onClick={() => removeFormFields(index)}></i>
                                        : null
                                }
                            </div><hr></hr></div>))}
                    <div class="button-section text-right">
                        <button type="button" class="btn btn-info btn-sm  " onClick={() => addFormFields()}>Add</button>
                    </div>
                    <div class="form-group">
                        <button type="button" onClick={handleSubmit} className="btn btn-danger btn-sm "  >Submit</button>
                    </div>
                </form>
            </LoadingOverlay>
        </div>
    )
}

export default AddBulkLeads